<template>
  <div class="page-container">
    <div v-html="knowContent"></div>
  </div>
</template>

<script>
import {KnowConfigModel} from "@/model/exp/KnowConfigModel";

export default {
  name: "studentKnow",
  data() {
    return {
      knowContent:""
    }
  },
  async mounted() {
    this.knowContent = await KnowConfigModel.getInfo()
  }
}
</script>

<style scoped>

</style>